import React, { useState,useEffect } from 'react';

function CheckboxBlock(props){
    const [cv, setCheck] = useState([]);
    useEffect(() => {
        const va = props.value !== ""?props.value.split(','):[];
        setCheck(va)
    }, [props.name]);
    function handleCheck(e){
        let ns=[...cv]
        if(e.target.checked){
           ns.push(e.target.value)
        }
        else{
            let ix=ns.findIndex(obj => obj === e.target.value)
            ns.splice(ix,1)
        }
        setCheck(ns);
        let nsj=(ns.join(','))
       props.handleCheckbox(props.name,nsj);
    }
    return (
        <div className={`csCheckboxWrap ${props.data.length > 5 ? 'two-sided' : ''}`}>
            {props.data.map((list,ix)=>
                 <div className='csCheckbox'  key={`${props.name}-o-${ix}`}>
            <input  id={`${props.name}-o-${ix}`} checked={cv.includes(list)} name={props.name} type='checkbox' className='csCheckbox__input' value={list} onChange={(e)=>handleCheck(e)}/>
            <span className='csCheckbox__placeholder'>
                <i className="fa fa-check" aria-hidden="true"/>
            </span>
            <label htmlFor={`${props.name}-o-${ix}`} className='csCheckbox__label'>{list}</label>
        </div>
            )}
        </div>
    )
}

export default CheckboxBlock
