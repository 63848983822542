import React, { useState,useEffect } from 'react';

function InputBox(props){
    const [status, setStatus] = useState(false);
    const [text, setText] = useState('');

    useEffect(() => {
        setText(props.value)
    }, [props.value]);
    const handleText = (e) => {
        setText(e.target.value);
        props.onTextChange(e);
    }


    let {name}=props

    return (
        <input name={name} type='text' className='csInput' placeholder='Type here' required value={text} onChange={(e)=>handleText(e)}/>

    )
}

export default InputBox;
