import {commonService} from "./common.services";

export const form = {
    listService,
    saveData
};

function listService(params) {
    return commonService.sendHttpRequest('library/initsurvey', params, "POST");
}

function saveData(params) {
    return commonService.sendHttpRequest('library/savesurvey', params, "POST");
}
