import React from 'react';

import logo from '../assets/images/RedeemXDefault.png';
import girl from '../assets/images/girl_vector.svg';

const Sidebar = (props) => {
    let {token} =props
    // console.log(props.data)
    let bgimage = 'https://redeemx.imgix.net/images/1692015060967.png'
    let logo = 'https://bsynapse.imgix.net/images/1691767792533.png'
    let bgColor = '000000'

    if(props.data.surveyBackgroundImage === ''){
        if(props.data.backgroundImage !== undefined && props.data.backgroundImage !== ''){
            bgimage = props.data.backgroundImage
            }
        }
    else{
        if(props.data.surveyBackgroundImage !== undefined && props.data.surveyBackgroundImage !== ''){
            bgimage = props.data.surveyBackgroundImage
            }
        }

    if(props.data.surveyBackgroundColor === ''){
        if(props.data.backgroundColor !== undefined && props.data.backgroundColor !== ''){
            bgColor = props.data.backgroundColor
            }
        }
    else{
        if(props.data.surveyBackgroundColor !== undefined && props.data.surveyBackgroundColor !== ''){
            bgColor = props.data.surveyBackgroundColor
            }
        }

    if(props.data.surveyLogo !== undefined && props.data.surveyLogo !== ''){
        logo = props.data.surveyLogo
        }
    else if(props.data.logo !== undefined && props.data.logo !== ''){
        logo = props.data.logo
        }
    // console.log(props.data)

    return (
        <div className='sidebar' style={{background: "#"+bgColor}}>
            {(props.data.status === "Success" || props.data.status === "AlreadySubmitted" || props.data.status === "InvalidQlistID" || props.data.status === "InvalidSignature" || props.data.status === "Expired" || props.data === '') &&
            <>
                <div className='logoIn'>
                    {!props.loader && <img src={logo} alt='logo'/>}
                </div>
                <div className='sidebar__content sidebar__content--hasProgress'>
                    <div className='sidebar__content__top'>
                        {!props.loader &&
                        <img src={bgimage} className='sidebarImage' alt='girl with love'/>}
                        <h1>{props.data.surveyTitle}</h1>
                    </div>
                    {props.id !== "" && props.data.status === "Success" &&
                    <div className='sidebar__content__bottom'>

                        {
                            props.showWelcomeMsg === false && 

                            <div className='completedProgess'>
                                <p className='completedProgess__text'>Question {props.id + 1}/{props.data.questions.length}</p>
                                <div className='completedProgess__bar'>
                                    <span className='completedProgess__bar__bg'
                                          style={{width: ((props.id / (props.data.questions.length - 1)) * 100) + "%"}}/>
                                </div>
                            </div>
                        }

                            
                    </div>
                    }
                </div>
            </>
            }
        </div>
    )
}

export default Sidebar
